<template>
  <!-- 集采商城二级列表 -->
  <Layout>
    <div class="main-shop">
      <div class="base-width">
        <!-- 搜索 -->
        <Search type shopCart record v-ani.fade-up @change="searchChange" />
        <!-- 面包屑 -->
        <BreadCrumb :list='routes' :icon='false' class="s22" v-ani.fade-up />
        <!-- 参数 -->
        <ProductParams :baseObj="baseObj" @change="seachFn" v-ani.fade-up />
        <!-- 筛选 -->
        <ProductFilter :totalNum="goodsTotal" @change="filterFn" :official="false" v-ani.fade-up />
        <!-- 产品列表 -->
        <div class="md-prod-list">
          <template v-for="item in goodsArr">
            <ProductItem :value='item' :key="item.goods_id" v-ani.fade-up />
          </template>
        </div>
        <!-- 分页 -->
        <Pagination v-show="goodsTotal > 0" :page="goodsData.p" :size="goodsData.page_num" :total="goodsTotal"
          v-ani.fade-up @change="changePage" :key="pagKey" />
      </div>
    </div>
  </Layout>
</template>

<script>
import { GoodsListApi, IndexBaseApi } from "@/request/api/shop";
export default {
  created() {
    this.goodsData.keyword = this.$route.query.keywords;
    this.goodsData.cat_name = this.$route.query.cat_name;
    this.goodsFn();
    // 基础数据
    IndexBaseApi({ cat_name: this.goodsData.cat_name }).then(({ status, data }) => {
      if (status == 200) {
        this.baseObj = data;
      }
    })
  },
  data() {
    return {
      goodsData: {
        p: 1,
        page_num: 15,
        material_type: "不限",
        material: "不限",
        limit_buy: "不限",
        brand: "不限",
        address: "不限",
        keyword: "",
        order: 1,
        sort: "",
        min_price: "",
        max_price: "",
        cat_name: ""
      },
      goodsArr: [],
      goodsTotal: 0,
      baseObj: {},
      routes: [
        { name: '首页', link: '/' },
        { name: '集采商城', link: '/shop' },
        { name: '商品搜索', link: '' }
      ],
      pagKey: 0,
    };
  },
  methods: {
    // 搜索
    seachFn(obj) {
      if (obj.typeNum == 1) {
        this.goodsData.material_type = obj.val;
      }
      if (obj.typeNum == 2) {
        this.goodsData.material = obj.val;
      }
      if (obj.typeNum == 3) {
        this.goodsData.limit_buy = obj.val;
      }
      if (obj.typeNum == 4) {
        this.goodsData.brand = obj.val;
      }
      if (obj.typeNum == 5) {
        this.goodsData.address = obj.val;
      }
      if (obj.typeNum == 6) {
        this.goodsData.cat_name = obj.val;
      }
      this.goodsData.p = 1;
      this.goodsFn();
    },
    searchChange(str) {
      this.goodsData.p = 1;
      this.goodsData.keyword = str;
      this.goodsFn();
    },
    // 参数筛选
    filterFn(obj) {
      this.goodsData.p = 1;
      this.goodsData.order = obj.order;
      this.goodsData.sort = obj.sort;
      this.goodsData.min_price = obj.min_price;
      this.goodsData.max_price = obj.max_price;
      this.goodsFn();
    },
    // 商品列表
    goodsFn() {
      GoodsListApi(this.goodsData).then(({ status, data }) => {
        if (status == 200) {
          this.goodsArr = data.list;
          this.goodsTotal = data.total;
          this.pagKey++;
        }
      })
    },
    // 分页
    changePage(page) {
      this.goodsData.p = page;
      this.goodsFn();
      window.scroll({ top: 200, behavior: "smooth", });
    },
  }
};
</script>

<style lang="scss" scoped>
.main-shop {
  padding-bottom: .6rem;

  .c-breadcrumbs {
    margin: .33rem 0 .26rem;
  }

  .md-pagination {
    margin-top: .4rem;
  }

  .md-prod-list {
    margin-top: .2rem;
    display: flex;
    flex-wrap: wrap;

    .c-product-item {
      width: 3.04rem;
      margin-bottom: .2rem;
      margin-right: .2rem;

      &:nth-of-type(5n) {
        margin-right: 0;
      }
    }
  }
}
</style>